<template>
  <div class="contact">
    <h1>Haben wir Ihr Interesse geweckt?</h1>
    <div class="contact-text">
      Haben Sie einen konkreten Wunsch? Gerne können wir uns über Ihre Anforderungen und Wünsche unterhalten. Kontaktieren Sie uns einfach unter folgender E-Mail-Adresse: <a href="mailto:info@mits.gmbh">info@mits.gmbh</a> und wir melden uns zügig bei Ihnen.
    </div>
  </div>
</template>

<style scoped lang="scss">
.contact {
  max-width: 800px;
  margin: auto;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &-text {
    font-size: 1.4rem;
  }
}
</style>
